<template>
  <div>
    <v-container>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Active Customers</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Customers"
            outlined
            background-color="white"
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
            v-model="searchTerm"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-checkbox
            v-model="showAll"
            label="Show All"
            @change="checkboxUpdated"
          ></v-checkbox>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-btn
              depressed
              color="accent"
              class="mr-2"
              @click="addCustomerForm.dialog = true"
            >
              <v-icon left>mdi-plus</v-icon>Add Customer
            </v-btn>
            <v-btn depressed light :to="{ name: 'module-customers-archived' }">
              <v-icon left>mdi-archive</v-icon>View Archived
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :headers="tableHeaders"
            :items="customers"
            :items-per-page="-1"
            no-data-text="No customers found"
          >
            <template v-slot:item.name="{ item }">
              <router-link
                :to="{
                  name: 'customer-profile-basic',
                  params: { customerId: item.id },
                }"
                >{{ item.name }}</router-link
              >
            </template>

            <template v-slot:item.updated_at="{ item }">
              {{ formatDateTime(item.updated_at, true) }}
            </template>

            <template v-slot:item.website="{ item }">
              <a target="_blank" :href="`${item.website}`">
                {{ item.website }}
              </a>
            </template>

            <template v-slot:item.relationship="{ item }">
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    :color="getRelationshipColor(item.relationship)"
                    v-on="on"
                    :loading="relationshipUpdating === item.id"
                  >
                    {{ getRelationshipLabel(item.relationship) }}
                    <v-icon x-small right>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-list dense>
                  <v-list-item
                    v-for="(relationship, index) in companyRelationships"
                    :key="index"
                    @click="
                      updateCustomerRelationship(item.id, relationship.value)
                    "
                  >
                    <v-list-item-title>
                      {{ relationship.label }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    class="mr-2"
                    @click="openEditCustomer(item.id)"
                  >
                    <v-icon x-small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit Customer</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="red lighten-4 red--text mr-2"
                    v-on="on"
                    @click="
                      archiveCustomerDialog = true;
                      archiveCustomerData = item;
                    "
                  >
                    <v-icon x-small>mdi-archive</v-icon>
                  </v-btn>
                </template>
                <span>Archive Customer</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="green lighten-4 green--text"
                    v-on="on"
                    :to="{
                      name: 'customer-profile-basic',
                      params: { customerId: item.id },
                    }"
                  >
                    <v-icon x-small>mdi-eye</v-icon>
                  </v-btn>
                </template>
                <span>View Customer</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="addCustomerForm.dialog" scrollable max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Add New Customer</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-5 grey lighten-4">
          <v-form
            @submit.prevent="addCustomer"
            method="post"
            id="customer-form"
          >
            <v-row>
              <v-col cols="6">
                <v-text-field
                  label="Customer Name *"
                  type="text"
                  v-model="addCustomerForm.name"
                  outlined
                  background-color="white"
                  dense
                  :error="addCustomerForm.errors.hasOwnProperty('name')"
                  :error-messages="addCustomerForm.errors['name']"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Website"
                  type="text"
                  v-model="addCustomerForm.website"
                  outlined
                  background-color="white"
                  dense
                  :error="addCustomerForm.errors.hasOwnProperty('website')"
                  :error-messages="addCustomerForm.errors['website']"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-combobox
                  :items="sectors"
                  label="Sector"
                  v-model="addCustomerForm.sector"
                  item-text="name"
                  :return-object="false"
                  chips
                  outlined
                  background-color="white"
                  dense
                  autocomplete="false"
                  :error="addCustomerForm.errors.hasOwnProperty('sector')"
                  :error-messages="addCustomerForm.errors['sector']"
                ></v-combobox>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="VAT Number"
                  type="text"
                  v-model="addCustomerForm.vat_number"
                  outlined
                  background-color="white"
                  dense
                  :error="addCustomerForm.errors.hasOwnProperty('vat_number')"
                  :error-messages="addCustomerForm.errors['vat_number']"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-select
                  label="Relationship"
                  :items="companyRelationships"
                  v-model="addCustomerForm.relationship"
                  item-text="label"
                  item-value="value"
                  outlined
                  background-color="white"
                  dense
                  :error="addCustomerForm.errors.hasOwnProperty('relationship')"
                  :error-messages="addCustomerForm.errors['relationship']"
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Short Code"
                  type="text"
                  v-model="addCustomerForm.shortcode"
                  outlined
                  background-color="white"
                  dense
                  class="shortcode-input"
                  :error="addCustomerForm.errors.hasOwnProperty('shortcode')"
                  :error-messages="addCustomerForm.errors['shortcode']"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Company Email"
                  type="text"
                  v-model="addCustomerForm.company_email"
                  outlined
                  background-color="white"
                  dense
                  class
                  :error="
                    addCustomerForm.errors.hasOwnProperty('company_email')
                  "
                  :error-messages="addCustomerForm.errors['company_email']"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  label="Notes"
                  v-model="addCustomerForm.notes"
                  outlined
                  background-color="white"
                  dense
                  class
                  :error="addCustomerForm.errors.hasOwnProperty('notes')"
                  :error-messages="addCustomerForm.errors['notes']"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="addCustomerForm.dialog = false"
            >Close</v-btn
          >
          <v-btn
            color="accent"
            text
            form="customer-form"
            type="submit"
            :loading="addCustomerForm.loading"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editCustomerForm.dialog" scrollable max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Edit Customer</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-5 grey lighten-4">
          <v-form
            @submit.prevent="editCustomer"
            method="post"
            id="edit-customer-form"
          >
            <v-row>
              <v-col cols="6">
                <v-text-field
                  label="Customer Name *"
                  type="text"
                  v-model="editCustomerForm.name"
                  outlined
                  background-color="white"
                  dense
                  :error="editCustomerForm.errors.hasOwnProperty('name')"
                  :error-messages="editCustomerForm.errors['name']"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Website"
                  type="text"
                  v-model="editCustomerForm.website"
                  outlined
                  background-color="white"
                  dense
                  :error="editCustomerForm.errors.hasOwnProperty('website')"
                  :error-messages="editCustomerForm.errors['website']"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-combobox
                  :items="sectors"
                  label="Sector"
                  v-model="editCustomerForm.sector"
                  item-text="name"
                  :return-object="false"
                  chips
                  outlined
                  background-color="white"
                  dense
                  autocomplete="false"
                  :error="editCustomerForm.errors.hasOwnProperty('sector')"
                  :error-messages="editCustomerForm.errors['sector']"
                ></v-combobox>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="VAT Number"
                  type="text"
                  v-model="editCustomerForm.vat_number"
                  outlined
                  background-color="white"
                  dense
                  :error="editCustomerForm.errors.hasOwnProperty('vat_number')"
                  :error-messages="editCustomerForm.errors['vat_number']"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-select
                  label="Relationship"
                  :items="companyRelationships"
                  v-model="editCustomerForm.relationship"
                  item-text="label"
                  item-value="value"
                  outlined
                  background-color="white"
                  dense
                  :error="
                    editCustomerForm.errors.hasOwnProperty('relationship')
                  "
                  :error-messages="editCustomerForm.errors['relationship']"
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Short Code"
                  type="text"
                  v-model="editCustomerForm.shortcode"
                  outlined
                  background-color="white"
                  dense
                  class="shortcode-input"
                  :error="editCustomerForm.errors.hasOwnProperty('shortcode')"
                  :error-messages="editCustomerForm.errors['shortcode']"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Company Email"
                  type="text"
                  v-model="editCustomerForm.company_email"
                  outlined
                  background-color="white"
                  dense
                  class
                  :error="
                    editCustomerForm.errors.hasOwnProperty('company_email')
                  "
                  :error-messages="editCustomerForm.errors['company_email']"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  label="Notes"
                  v-model="editCustomerForm.notes"
                  outlined
                  background-color="white"
                  dense
                  class
                  :error="editCustomerForm.errors.hasOwnProperty('notes')"
                  :error-messages="editCustomerForm.errors['notes']"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="editCustomerForm.dialog = false"
            >Close</v-btn
          >
          <v-btn
            color="accent"
            text
            type="submit"
            form="edit-customer-form"
            :loading="editCustomerForm.loading"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="archiveCustomerDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Archive Customer</v-card-title>
        <v-card-text>
          Are you sure you want to archive
          {{ archiveCustomerData ? archiveCustomerData.name : "" }}?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="archiveCustomerDialog = false"
            >No, cancel</v-btn
          >
          <v-btn
            color="success"
            depressed
            :loading="archiveCustomerLoading"
            @click="archiveCustomer()"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchTerm: "",
      tableHeaders: [
        { text: "Customer", value: "name" },
        { text: "Website(s)", value: "website" },
        { text: "Relationship", value: "relationship" },
        { text: "Last updated", value: "updated_at" },
        { text: "Actions", value: "actions", align: "right" },
      ],
      showAll: false,
      addCustomerForm: {
        dialog: false,
        name: null,
        website: null,
        sector: null,
        vat_number: null,
        relationship: null,
        shortcode: null,
        company_email: null,
        notes: null,
        loading: false,
        errors: {},
      },
      editCustomerForm: {
        dialog: false,
        id: null,
        name: null,
        website: null,
        sector: null,
        vat_number: null,
        relationship: null,
        company_email: null,
        notes: null,
        shortcode: null,
        loading: false,
        errors: {},
      },
      companyRelationships: [
        { value: "partner", label: "Partner", color: "success" },
        { value: "support", label: "Support", color: "warning" },
        {
          value: "infrastructure",
          label: "Infrastructure",
          color: "secondary",
        },
        { value: "ad-hoc", label: "Ad-hoc", color: "blue white--text" },
        { value: "left-us", label: "Left Us", color: "default" },
        { value: "oob", label: "OOB", color: "black white--text" },
        { value: "na", label: "N/A", color: "error" },
      ],
      relationshipUpdating: null,
      archiveCustomerDialog: false,
      archiveCustomerLoading: false,
      archiveCustomerData: null,
    };
  },

  computed: {
    customers() {
      let customers = this.$store.state.customers["customers"];

      if (this.showAll == false) {
        customers = customers.filter(
          (c) =>
            c.relationship !== "oob" &&
            c.relationship !== "na" &&
            c.relationship !== "left-us"
        );
      }

      if (this.searchTerm !== "") {
        customers = customers.filter((c) => {
          const name = c.name.toLowerCase();
          const shortcode = c.shortcode ? c.shortcode.toLowerCase() : "";
          return (
            name.includes(this.searchTerm.toLowerCase()) ||
            shortcode.includes(this.searchTerm.toLowerCase())
          );
        });
      }

      return customers;
    },
    sectors() {
      return this.$store.state.customers["sectors"];
    },
  },

  methods: {
    checkboxUpdated(newValue) {
      if (newValue) {
        this.showAll = true;
      }
    },

    addCustomer: function () {
      const appId = this.$route.params.id;
      const name = this.addCustomerForm.name;
      const website = this.addCustomerForm.website;
      const sector = this.addCustomerForm.sector;
      const vat_number = this.addCustomerForm.vat_number;
      const relationship = this.addCustomerForm.relationship;
      const shortcode = this.addCustomerForm.shortcode;
      const company_email = this.addCustomerForm.company_email;
      const notes = this.addCustomerForm.notes;

      this.addCustomerForm.loading = true;

      this.$store
        .dispatch("customers/addCustomer", {
          appId,
          name,
          website,
          sector,
          vat_number,
          relationship,
          shortcode,
          company_email,
          notes,
        })
        .then(() => {
          this.addCustomerForm = {
            dialog: false,
            name: null,
            website: null,
            sector: null,
            vat_number: null,
            relationship: null,
            shortcode: null,
            company_email: null,
            notes: null,
            loading: false,
            errors: {},
          };
        })
        .catch((err) => {
          this.addCustomerForm.errors = err.response.data.errors;
          this.addCustomerForm.loading = false;
        });
    },
    openEditCustomer: function (id) {
      const customer = this.customers.find((c) => c.id === id);
      const sector = this.sectors.find((s) => s.id === customer.sector);

      this.editCustomerForm = {
        dialog: true,
        id: id,
        name: customer.name,
        website: customer.website,
        sector: customer.sector !== null ? [sector.name] : null,
        vat_number: customer.vat_number,
        relationship: customer.relationship,
        shortcode: customer.shortcode,
        company_email: customer.company_email,
        notes: customer.notes,
        loading: false,
        errors: {},
      };
    },
    editCustomer: function () {
      const appId = this.$route.params.id;
      const customerId = this.editCustomerForm.id;
      const name = this.editCustomerForm.name;
      const website = this.editCustomerForm.website;
      const sector = this.editCustomerForm.sector;
      const vat_number = this.editCustomerForm.vat_number;
      const relationship = this.editCustomerForm.relationship;
      const shortcode = this.editCustomerForm.shortcode;
      const company_email = this.editCustomerForm.company_email;
      const notes = this.editCustomerForm.notes;

      this.editCustomerForm.loading = true;

      this.$store
        .dispatch("customers/editCustomer", {
          appId,
          customerId,
          name,
          website,
          sector,
          vat_number,
          relationship,
          shortcode,
          company_email,
          notes,
        })
        .then(() => {
          this.editCustomerForm = {
            dialog: false,
            id: null,
            name: null,
            website: null,
            sector: null,
            vat_number: null,
            relationship: null,
            shortcode: null,
            company_email: null,
            notes: null,
            loading: false,
            errors: {},
          };
        })
        .catch((err) => {
          this.editCustomerForm.errors = err.response.data.errors;
          this.editCustomerForm.loading = false;
        });
    },
    updateCustomerRelationship: function (id, relationship) {
      const appId = this.$route.params.id;
      this.relationshipUpdating = id;

      this.$store
        .dispatch("customers/updateRelationship", {
          appId,
          id,
          relationship,
        })
        .then(() => {
          this.relationshipUpdating = null;
        });
    },
    archiveCustomer: function () {
      const appId = this.$route.params.id;
      const id = this.archiveCustomerData.id;

      this.archiveCustomerLoading = true;

      this.$store
        .dispatch("customers/archiveCustomer", {
          appId,
          id,
        })
        .then(() => {
          this.archiveCustomerData = null;
          this.archiveCustomerLoading = false;
          this.archiveCustomerDialog = false;
        });
    },
    getRelationshipLabel: function (value) {
      if (value === null) {
        return "Select";
      }

      const relationship = this.companyRelationships.find(
        (r) => r.value === value
      );

      return relationship.label;
    },
    getRelationshipColor: function (value) {
      if (value === null) {
        return "default";
      }

      const relationship = this.companyRelationships.find(
        (r) => r.value === value
      );

      return relationship.color;
    },
  },
};
</script>

<style>
.shortcode-input {
  max-width: 50%;
}
</style>
